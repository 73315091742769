<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">         
    <div class="container mt-5 mb-5 pb-2" id="custom-cards">
      <h3 class="pb-2 border-bottom text-dark-blue">Payments Report</h3>
      <div class="row col-md-12 justify-content-between mb-3">
        <div class="col-8 text-start align-middle">
          <label for=""> Member</label>
          <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" @select="SelectedMember" @clear="ClearMember" class="size-multiselect">
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                <img :src="option.icon">
                <a :style="{'color':option.color}">{{ option.label }}</a>
                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon"> {{ option.label }}
            </template>
          </Multiselect>
        </div>       
        <div class="col-4 input-group-sm text-start align-middle">
          <label for=""> Date</label>
          <v-date-picker v-model="range" :model-config="modelConfig" is-range @dayclick="onDayClick">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="form-control input-h bg-white input-group-lg border px-2 py-1 rounded" :value="inputValue.start" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
        </div>
      </div>     
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">  
        <div class="col mb-3">
          <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
            <h6 class="text-start card-title "><i class="far fa-clock"></i> Payments</h6>
            <p class="card-text text-start mb-2 text-orange">
              {{count}}
            </p> 
          </div>                                   
        </div>                    
        <div class="col mb-3 d-flex justify-content-around">
          <div class="rounded align-items-center h-30 shadow p-3 bg-body card-body">
            <h6 class="text-start card-title "><i class="fas fa-dollar-sign"></i> Amount</h6>
            <p class="card-text text-start mb-2 text-orange">
              $ {{total}}
            </p> 
          </div>                                   
        </div> 
        <div class="col mb-3">
          <div class="text-end">
            <!--<div class="cursor-pointer d-inline m-3" @click="mostrar = !mostrar ">
              Graphic <i class="fas fa-chart-bar"></i>
            </div>-->
            <div class="cursor-pointer d-inline m-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Export <i class="fas fa-download"></i> 
            </div>
            <ul class="dropdown-menu">
              <div class="m-1">
                <li>
                  <a class="dropdown-item" href="#" @click="valueExcelSelected">To EXCEL</a>
                </li>
                <!--<li>
                  <a class="dropdown-item " href="#" @click="valuePdfSelected">To PDF</a>
                </li>-->
                <li>
                  <a class="dropdown-item " href="#" @click="valueCsvSelected">To CSV</a>
                </li>
              </div>                           
            </ul>   
          </div>
        </div> 
      </div>               
      <div id="graph" class="row m-2" v-show="mostrar">
        <div class="col-12" style="border:1px solid #CCC">
          Graphic
          <!-- Aqui irhia el grafico-->
        </div>
      </div>
      <div class="card card-cover overflow-hidden text-white bg-white shadow-sm p-3 mt-5 mb-5 mt-0 bg-body rounded" v-if="ListPayments.status != 'false'">
        <div class="table-responsive">
          <table class="table table-striped responsive" id="payments-table">
            <!--<thead class="  text-start ">
              <th class="fw-bold" colspan="3">Member: {{nameMember}}</th>
            </thead>-->
            <thead>
              <tr class="text-start">
                <th scope="col"><span class="text-blue">Member: </span>{{nameMember}}</th>
                <th scope="col"><span class="text-blue"></span></th>
                <th scope="col"><span class="text-blue"></span></th>
              </tr>
              <tr class="text-start">     
                <th scope="col"><span class="text-blue">Payment type</span></th>
                <th scope="col"><span class="text-blue">Paid on</span></th>
                <th scope="col"><span class="text-blue">Amount</span></th>
              </tr> 
            </thead>
            <tbody class="mb-special shadow">
              <tr class="text-start" v-for="(obj, key, index) in ListPayments.list" :key="index">                         
                <td class="text-start">
                  {{obj.payment_type}}
                </td>
                <td class="text-start">
                  {{obj.paid_on}}
                </td>
                <td class="text-start">
                  {{obj.amount}} $
                </td>
              </tr>                                  
            </tbody>
          </table>                            
        </div>
      </div>
    </div>          
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import Multiselect from '@vueform/multiselect'
  import moment from 'moment-timezone';
  import $ from 'jquery';

  export default 
  {  
    name: 'Payments',
    components: 
    {
      Multiselect
    },
    data: () => (
    {
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      range: 
      {
        start: null,//new Date(),
        end: null//new Date()
      },
      ListPayments: 
      {
        status: "false",
        list: {}
      },
      mostrar: false,
      count: '-',
      total: '-',
      nameMember: '-',
      PostPayments: 
      {
        date_init: null,//new Date(),
        date_finish: null,//new Date(),
        id_session: '',
        timezone: moment.tz.guess(true),
      },
      GetExportPost: {
        date_init: null,
        date_finish: null,
        timezone: moment.tz.guess(true),
        download: 1,
        format: "",
        id_session: ""
      },
    }),
    methods: 
    {
      //Check Fields
      ValidateFields() 
      {
        if(this.MultiSelectMembers.value == "" || this.MultiSelectMembers.value == null || this.PostPayments.date_init == null || this.PostPayments.date_finish == null)
        {
          return true
        }
      },
      valueExcelSelected() 
      {
        this.GetExportPost.format = "xlsx";
        if(this.ValidateFields() != true)
        {  
          this.sendExport();
        }
      },
      valueCsvSelected() 
      {
        this.GetExportPost.format = "csv";
        if(this.ValidateFields() != true)
        {  
          this.sendExport();
        }
      },
      sendExport() 
      {
        Api.post("/reports/payments", this.GetExportPost).then((result) => {
          if (result.data.status == "true") {
            /*if(result.data.link != undefined) {
              var win = window.open(result.data.link, '_blank');
            }*/
            var nameUrl = result.data.name;
            var fileLink = document.createElement('a');
            fileLink.href = result.data.link;
            document.body.appendChild(fileLink);
            fileLink.setAttribute('download', nameUrl);
            fileLink.click();
          }
        })
      },
      GetPaymentReport()
      {
        Api.post("/reports/payments", this.PostPayments).then((result) => 
        {

          this.ListPayments.status = result.data.status

          if (this.ListPayments.status == "true") 
          {
            //Exists Datatable?
            if ($.fn.dataTable.isDataTable('#payments-table'))
            {
              var table = $('#payments-table').DataTable();
              table.destroy();
            }

            this.count = result.data.count;
            this.total = result.data.total;
            this.nameMember = result.data.name;
            this.ListPayments.list = result.data.payments;

            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      //Init second table
      InitDatatable() 
      {
        var table = $('#payments-table').DataTable(
        {
          dom: 'lftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      //Selected Member method
      SelectedMember(option) 
      {
        this.myid = option;

        this.PostPayments.id_session = this.myid;
        this.GetExportPost.id_session = this.myid;
        /*this.PostPay2.id_session = this.myid;
        this.PostSelect.id_session = this.myid;
        this.PostList.id_session = this.myid;
        this.PostPending2.id_session = this.myid;

        this.ListPayment(1);
        this.PaymentPending(1);*/

        if(this.ValidateFields() != true)
        {  
          this.GetPaymentReport();
        }
      },
      //Clear member method
      ClearMember(option) 
      {
        //this.ListPayment(2);
        //this.PaymentPending(2);
      },
      onDayClick(day) 
      {
        this.PostPayments.date_init = this.range.start;
        this.PostPayments.date_finish = this.range.end;

        this.GetExportPost.date_init = this.range.start;
        this.GetExportPost.date_finish = this.range.end;

        if(this.ValidateFields() != true)
        {  
          this.GetPaymentReport();
        }
        /*this.PostPay.date_init = this.range.start;
        this.PostPay.date_finish = this.range.end;

        this.PostPay2.date_init = this.range.start;
        this.PostPay2.date_finish = this.range.end;

        this.PostSelect.date_init = this.range.start;
        this.PostSelect.date_finish = this.range.end;
        
        if(this.ValidateFields() != true)
        {
          this.SendSelect();
        }*/
      },
      //Get Members
      GetMembers() 
      {
        Api.post("/member/list", "").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.member

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }
                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
    },
    mounted() 
    {
      this.GetMembers()
    }
  }
</script>

<style scoped></style>
