<template>
   <HeaderDash />
   <SideMenu /> 
   <ContentPayments /> 
</template>

<script>
// Importando los componententes pertenecientes a esta vista
import HeaderDash from '@/components/team/dashboard/HeaderDash.vue'
import SideMenu from '@/components/team/dashboard/SideMenu.vue'
import ContentPayments from '@/components/team/dashboard/modules/report/payments/ContentPayments.vue'

export default 
{
  name: 'Payments',
  components: 
  {
    HeaderDash,
    SideMenu,
    ContentPayments
  }
}
</script>

<style>


</style>